.app-calendar-time-picker {
  &::-webkit-scrollbar {
    width: 0.6rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: #979197;
  }
}

.app-calendar {
  .app-calendar-time-input {
    border-color: #788af7;
    color: #788af7;
  }
}

.react-calendar {
  background: white;
  border: none;
  height: 15rem !important;
  width: calc(12.25rem + 26px) !important;
  padding: 0px 13px;

  span {
    letter-spacing: normal;
  }
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: 'Figtree', sans-serif;
  font-weight: 600;
}

.react-calendar__month-view__days abbr {
  display: none !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
  font-size: 0.8rem;
  text-decoration: none !important;
  text-transform: uppercase;
  color: #788af7;
}

.react-calendar button {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
  background: rgba(242, 243, 254, 0.7);
  color: #788af7;
}

.react-calendar__navigation {
  display: flex;
  height: 34px;
}

.react-calendar__navigation__label__labelText span {
  line-height: 1.25;
  letter-spacing: 0.165rem;
}

.react-calendar__navigation button {
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: white;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  display: flex;
  max-width: 100%;
  background: none;
  text-align: center;
  font: inherit;
  font-size: 1rem;
  box-sizing: border-box;
  -webkit-box-align: center;
  align-items: center;
  min-width: 0px;
  min-height: 0px;
  flex-direction: column;
  height: 1.75rem;
  width: 1.75rem;
  -webkit-box-pack: center;
  justify-content: center;
}

.react-calendar__tile:disabled {
  background: #cacccd;
  opacity: 0.7;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
  background: #cacccd;
  opacity: 0.7;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: var(--dashboard-bg-darken);
  color: #788af7;
}

.react-calendar__tile--now {
  background: #ffe8bc !important;

  span { color: #788af7 !important; }
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: var(--dashboard-bg-darken);
}

.react-calendar__tile--hasActive {
  background: #788af7;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #788af7;

  span { color: white; }
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--dashboard-bg-darken);

  span { color: #788af7; }
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: var(--dashboard-bg-darken);
}

.react-calendar__tile--range {
  background-color: var(--dashboard-bg-darken);

  span { color: #788af7; }
}

.react-calendar__tile--rangeEnd {
  background-color: #788af7;

  span { color: white; }
}

.react-calendar__tile--rangeStart {
  background-color: #788af7;

  span { color: white; }
}

.authCalendar {
  .app-calendar {
    .app-calendar-time-input {
      border-color: #038BFC;
      color: #038BFC;
    }
  }

  .react-calendar__navigation__label__labelText span {
    letter-spacing: 0.12rem;
    color: #3C3C3C;
  }

  .react-calendar__month-view__weekdays__weekday abbr {
    color: #3C3C3C;
  }

  .react-calendar button:enabled:hover {
    background: #D7EDFF;
    color: #038BFC;
  }

  .react-calendar__tile:disabled {
    background: #D0D0D0;
    opacity: 0.7;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background: #D7EDFF;
    color: #004FFE;
  }

  .react-calendar__tile.react-calendar__month-view__days__day {
    span { color: #3C3C3C };
  }

  .react-calendar__tile.react-calendar__month-view__days__day:enabled:hover {
    span { color: #004FFE };
  }

  .react-calendar__tile--now {
    background: #004FFE !important;

    span { color: white !important; }
  }

  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #F0F6FF;
  }

  .react-calendar__tile--hasActive {
    background: #F0F6FF;
  }

  .react-calendar__tile--active:enabled,
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #D7EDFF;

    span { color: #004FFE; }
  }

  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #F0F6FF;
  }

  .react-calendar__tile--range {
    background-color: #EFF7FF;

    span { color: #004FFE; }
  }

  .react-calendar__tile--rangeEnd {
    background-color: #004FFE !important;

    span { color: white !important; }
  }

  .react-calendar__tile--rangeStart {
    background-color: #004FFE !important;

    span { color: white !important; }
  }

  .react-calendar__month-view__weekdays__weekday abbr {
    color: #004FFE;
  }
}

.rangeCalendar {
  .react-calendar__tile--now {
    background: #3C3C3C !important;
  }
}
