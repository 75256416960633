@import 'tools';
@import 'stripe_overrides.scss';
@import 'react_tooltip_overrides.scss';
@import 'react_calendar_overrides.scss';
@import 'bamboohr_overrides.scss';
@import 'grommet_overrides.scss';
@import 'react_tags_overrides.scss';

body, html {
  margin: 0px;
  word-break: break-word;
  font-size: 26px;
  min-width: 280px;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
}

@media only screen and (max-width: 159.9375em) {
  body, html { font-size: 24px; }
}

@media only screen and (max-width: 145.9375em) {
  body, html { font-size: 22px; }
}

@media only screen and (max-width: 131.9375em) {
  body, html { font-size: 20px; }
}

@media only screen and (max-width: 117.9375em) {
  body, html { font-size: 18px; }
}

@media only screen and (max-width: 103.9375em) {
  body, html { font-size: 16px; }
}

@media only screen and (max-width: 89.9375em) {
  body, html { font-size: 14px; }
}

@media only screen and (max-width: 82.9375em) {
  body, html { font-size: 13px; }
}

@media only screen and (max-width: 75.9375em) {
  body, html { font-size: 12px; }
}


img {
  vertical-align: middle;
  max-width: 100%;;
  width: 100%;
}

input, textarea { font-family: 'Figtree', sans-serif !important; }

input[type=file],
input[type=file]::-webkit-file-upload-button {
  cursor: pointer !important;
}

input[type=file]:disabled,
input[type=file]:disabled::-webkit-file-upload-button {
  cursor: default !important;
}

.line-through { text-decoration: line-through !important; }
.preline { white-space: pre-line; }

.disabled { cursor: default !important; }

.visibility-hidden { visibility: hidden; }

.relative { position: relative; }

.absolute {
  position: absolute;
  right: 0;
  left: 0;
}

.image-light {
  -webkit-filter: brightness(0) invert(1);
  filter:  brightness(0) invert(1);
}

// Lazyload BEGIN *
.fade-out.lazyloaded {
  opacity: 1;
}
// Lazyload END *

// Fading Text BEGIN *
.fade-out {
  will-change: opacity;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.75s ease-out;
  -o-transition: opacity 0.75s ease-out;
  transition: opacity 0.75s ease-out;
}
// Fading Text END *

// DASHBOARD BEGIN *
.dashboard {
  .dragscroll {
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .ondrag {
    cursor: move;

    > div {
      -ms-touch-action: none;
      touch-action: none;
      pointer-events: none;
    }
  }
}
// DASHBOARD END *

// MOBILE SCROLLABLE TABLE
.scrollable-table {
  tbody {
    scrollbar-width: thin;
    scrollbar-color: #979197 white;

    &::-webkit-scrollbar {
      width: 0.6rem;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: #979197;
    }
  }
}

@media only screen and (max-width: 48em) {
  .scrollable-table {
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    overflow: hidden;

    thead {
      display: flex;
      display: -webkit-box;
      display: -ms-flexbox;
      flex: 0 0 auto;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      width: 70px;
      border-right: 1px solid #9fa1a4;
    }

    tbody {
      display: flex;
      display: -webkit-box;
      display: -ms-flexbox;
      position: relative;
      overflow-x: auto;
      overflow-y: hidden;
      width: calc(100% - 97px);

      &::-webkit-scrollbar {
        width: auto;
        height: 0.6rem;
      }

      tr {
        width: 100%;
      }

      td, th {
        max-width: 205px;
        width: 100%;
      }

      td {
        align-content: center;
      }
    }

    tr {
      display: flex;
      display: -webkit-box;
      display: -ms-flexbox;
      flex-direction: column;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      max-width: 225px;
      width: 100%;
      flex: 0 0 auto;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
    }

    th {
      text-align: end;
      padding-left: 0;
      align-content: center;
    }

    td, th {
      display: block;
      border: none !important;
      height: 45px;
    }

    .metrics-td {
      flex: 1;
    }

    td span {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

@media only screen and (max-width: 25em) {
  .scrollable-table {
    tbody {
      width: 75%;
    }

    td, th {
      height: 55px;
    }
  }

  .table-features {
    flex-flow: wrap-reverse;
    justify-content: flex-end !important;

    div:nth-child(2) {
      margin-bottom: 0;
    }
  }
}
